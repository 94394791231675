.transition(@transition) {
  -webkit-transition: @transition;
  -moz-transition: @transition;
  -o-transition: @transition;
  transition: @transition;
}

.borderRadius(@borderRadius) {
  -webkit-border-radius: @borderRadius;
  -moz-border-radius: @borderRadius;
  border-radius: @borderRadius;
}

.boxShadow(@boxShadow) {
  -webkit-box-shadow: @boxShadow;
  -moz-box-shadow: @boxShadow;
  -o-box-shadow: @boxShadow;
  box-shadow: @boxShadow;
}

.boxSizing(@boxSizing) {
  box-sizing: @boxSizing;
}

.backgroundImageSet(@1x, @2x) {
  background-image: url(@1x);
  background-image: -webkit-image-set(url(@1x) 1x, url(@2x) 2x);
  background-image: image-set(url(@1x) 1x, url(@2x) 2x);
}

.userSelect(@userSelect) {
  -webkit-touch-callout: @userSelect;
  -webkit-user-select: @userSelect;
  -moz-user-select: @userSelect;
  -ms-user-select: @userSelect;
  user-select: @userSelect;
}

.blur(@blur) {
  -webkit-filter: blur(@blur);
  -ms-filter: blur(@blur);
  filter: blur(@blur);
}

.maskImage(@maskImage) {
  mask-image: url(@maskImage);
  -webkit-mask-image: url(@maskImage);
}

.rotate(@deg) {
  -ms-transform: rotate(@deg);
  transform: rotate(@deg);
}

.appearance(@appearance) {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.centeredIcon(@svg) {
  mask-image: url(@svg);
  -webkit-mask-image: url(@svg);
  @centeredMask();
}

@centeredMask: {
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;
};

.rotate() {
  animation: spin 1s infinite cubic-bezier(0.5, 0, 0.5, 1);

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.contentSize(@size, @width: 1.5px) {
  inline-size: @size;
  block-size: @size;
  border-width: @width;
}

.backgroundBorderColor(@background,@border) {
  background-color: @background;
  border-color: @border;
}

.maskSize (@size) {
  mask-size: @size;
  -webkit-mask-size: @size;
}
