@font-face {
  font-family: "ALS Hauss";
  src: local("ALS Hauss Regular"), local("ALS-Hauss-Regular"),
    url("../fonts/ALSHauss-Regular.woff2") format("woff2"),
    url("../fonts/ALSHauss-Regular.woff") format("woff"),
    url("../fonts/ALSHauss-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "ALS Hauss";
  src: local("ALS Hauss Medium"), local("ALS-Hauss-Medium"),
    url("../fonts/ALSHauss-Medium.woff2") format("woff2"),
    url("../fonts/ALSHauss-Medium.woff") format("woff"),
    url("../fonts/ALSHauss-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "ALS Hauss";
  src: local("ALS Hauss Bold"), local("ALS-Hauss-Bold"),
    url("../fonts/ALSHauss-Bold.woff2") format("woff2"),
    url("../fonts/ALSHauss-Bold.woff") format("woff"),
    url("../fonts/ALSHauss-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
