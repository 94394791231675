@import url("normalize.css");
@import url("global");
@import url("fonts");
@import url("variables");
@import url("mixins");

:root {
  // Хранилище для глобальных css кастомных свойств которые не зависят от темы
  --sidebar-width: 225px;
  .custom-scroll();
}

html {
  height: 100%;

  .boxSizing(border-box);
}

*,
*::before,
*::after {
  .boxSizing(inherit);
}

body {
  height: 100%;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  padding: 0;
}

[type="text"],
[type="password"] {
  outline: 0;
  display: block;
  width: 100%;
  height: 44px;
  font-size: 20px;
  line-height: 28px;
  vertical-align: middle;
  padding: 8px;

  .borderRadius(8px);

  &::placeholder,
  &::-webkit-input-placeholder {
    color: @lightGray;
  }

  &.invalid {
    border-color: @red!important;
  }
}

[type="submit"] {
  outline: 0;
  cursor: pointer;
  display: block;
  color: @white;
  width: 100%;
  height: 60px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  vertical-align: middle;
  background-color: @blue;
  padding: 16px;
  border: none;

  .borderRadius(60px);
}

.light-corporate-theme {
  color: @black;
  background-color: @grey;
  font-family: "ALS Hauss", Arial, sans-serif;

  [type="text"],
  [type="password"] {
    color: @black;
    background-color: @white;
    border: 1px solid #e1e5ea;
  }
}

.dark-corporate-theme {
  color: @white;
  background-color: @dark;
  font-family: "ALS Hauss", Arial, sans-serif;

  [type="text"],
  [type="password"] {
    color: @white;
    background-color: @dark;
    border: 1px solid #394754;

    &::placeholder,
    &::-webkit-input-placeholder {
      color: #889caf;
      opacity: 1;
    }
  }

  [type="submit"] {
    outline: 0;
    cursor: pointer;
    display: block;
    color: @white;
    width: 100%;
    height: 60px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    vertical-align: middle;
    background-color: @blue;
    padding: 16px;
    border: none;

    .borderRadius(60px);
  }
}

.educational-theme {
  color: @white;
  font-weight: 900;
  font-family: "Arial Black", Arial, sans-serif;
  text-transform: uppercase;
  background-color: @dark;

  [type="text"],
  [type="password"] {
    color: @white;
    font-weight: 900;
    background-color: @dark;
    border: 1px solid #383838;

    &::placeholder,
    &::-webkit-input-placeholder {
      color: @color-grey-10;
      opacity: 1;
    }
  }

  [type="submit"] {
    color: @black;
    font-weight: 900;
    text-transform: uppercase;
    background-color: @yellow;
  }
}

html.educational {
  color-scheme: educational;

  textarea {
    &::placeholder,
    &::-webkit-input-placeholder {
      color: @color-grey-10;
      opacity: 1;
    }
  }

  body {
    font-weight: 900;
    text-transform: uppercase;
    font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
    font-size: 14px;
    line-height: 18px;
  }

  /* Typography */
  --h0-size: none;
  --h0-height: none;
  --h1-size: 32px;
  --h1-height: 36px;
  --h2-size: 28px;
  --h2-height: 32px;
  --h3-size: 24px;
  --h3-height: 28px;
  --h4-size: 20px;
  --h4-height: 24px;
  --h5-size: none;
  --h5-height: none;
  --h6-size: none;
  --h6-height: none;
  --h-weight: 900;

  /* Text */
  --t-m1-size: 20px;
  --t-m1-height: 28px;
  --t-m2-size: 16px;
  --t-m2-height: 20px;
  --t-m3-size: 14px;
  --t-m3-height: 18px;
  --t-m4-size: 12px;
  --t-m4-height: 16px;
  --t-m-weight: 900;

  /* r */
  --t-r-weight: 900;
  --t-r1-size: 16px;
  --t-r1-height: 20px;
  --t-r2-size: 14px;
  --t-r2-height: 20px;
  --t-r3-size: 12px;
  --t-r3-height: 16px;
  --t-r4-size: 10px;
  --t-r4-height: 16px;

  text-transform: uppercase;

  --text-transform: uppercase;
  --background-color: @color-background-dark-95;

  /* Checkbox */
  --checkbox-style-slide-border-color: @color-primary-yellow;
  --checkbox-style-presentation-border-color: @color-grey-40;
  --checkbox-style-presentation-border-hover-color: @color-transparency-white-20;
  --checkbox-check-border-checked-color: @color-primary-yellow;
  --checkbox-style-presentation-checked-background-color: @color-grey-60;
  --checkbox-check-border-checked-color-light: @color-primary-yellow;
  --checkbox-flag-border-checked-color: @color-primary-yellow;
  --checkbox-style-slide-background-color: @color-grey-80;
  --checkbox-style-presentation-background-color: @color-grey-60;

  --checkbox-style-presentation-checked-border-color: @color-grey-40;
  --checkbox-style-presentation-checked-border-hover-color: @color-transparency-white-20;
  --checkbox-check-background-checked-color: @color-grey-80;
  --checkbox-flag-background-checked-color: @color-grey-80;
  --checkbox-mask-checked-color: @color-primary-yellow;
  --checkbox-style-presentation-mask-checked: @color-primary-yellow;
  --checkbox-flag-mask-color: @color-primary-yellow;

  /* Button */
  --button-fill-background-default-color-primary: @color-primary-yellow;
  --button-fill-background-hover-color-primary: @color-primary-yellow-hover;
  --button-fill-background-active-color-primary: @color-primary-yellow-active;
  --button-fill-background-disable-color-primary: @color-grey-20;
  --button-fill-border-focus-color-primary: @color-white;
  --button-fill-border-disable-color-primary: none;
  --button-fill-border-focus-width: 3px;
  --button-fill-border-disabled-width: none;
  --button-fill-padding-s: 0;
  --button-fill-padding-m: 8px 12px;
  --button-fill-padding-l: 12px 24px;
  --button-fill-padding-xl: 18px 24px;
  --button-fill-block-size-s: none;
  --button-fill-block-size-m: 36px;
  --button-fill-block-size-l: 44px;
  --button-fill-block-size-xl: 60px;
  --button-fill-line-height-s: none;
  --button-fill-line-height-m: .d-text-regular-r2[line-height];
  --button-fill-line-height-l: .d-text-regular-r1[line-height];
  --button-fill-line-height-xl: .d-headline-h4[line-height];
  --button-fill-font-size-s: none;
  --button-fill-font-size-m: .d-text-regular-r2[font-size];
  --button-fill-font-size-l: .d-text-regular-r1[font-size];
  --button-fill-font-size-xl: .d-headline-h4[font-size];
  --button-fill-font-weight-s: none;
  --button-fill-font-weight-m: .d-text-regular-r2[font-weight];
  --button-fill-font-weight-l: .d-text-regular-r1[font-weight];
  --button-fill-font-weight-xl: .d-headline-h4[font-weight];
  --button-fill-font-color-primary: @color-general-black;
  --button-fill-font-color-hover-primary: @color-general-black;
  --button-fill-font-color-active-primary: @color-general-black;
  --button-fill-font-color-disable-primary: @color-grey-60;
  --button-fill-font-color-secondary: @color-general-black;
  --button-fill-font-color-hover-secondary: @color-general-black;
  --button-fill-font-color-active-secondary: @color-general-black;
  --button-border-focus-color: @color-general-white;
  --button-border-border-default-color: @color-grey-10;
  --button-border-border-hover-color-secondary: @color-grey-20;
  --button-border-border-active-color-secondary: @color-grey-30;
  --button-border-border-focus-color-secondary: @color-white;
  --button-border-border-focus-width-secondary: 3px;
  --button-border-background-default-color: @color-background-dark;
  --button-border-background-default-color-secondary: @color-background-dark;
  --button-border-padding-xs: none;
  --button-border-padding-s: none;
  --button-border-padding-m: 8px 12px;
  --button-border-padding-l: 12px 24px;
  --button-border-padding-xl: none;
  --button-border-block-size-m: 36px;
  --button-border-block-size-l: 44px;
  --button-border-font-size-xs: none;
  --button-border-font-size-s: none;
  --button-border-font-size-m: .d-text-regular-r2() [font-size];
  --button-border-font-size-l: .d-text-regular-r1() [font-size];
  --button-border-font-size-xl: none;
  --button-border-font-weight-xs: none;
  --button-border-font-weight-s: none;
  --button-border-font-weight-m: .d-text-regular-r2() [font-weight];
  --button-border-font-weight-l: .d-text-regular-r1() [font-weight];
  --button-border-font-weight-xl: none;
  --button-border-line-height-m: .d-text-regular-r2() [line-height];
  --button-border-line-height-l: .d-text-regular-r1() [line-height];
  --button-border-font-color-secondary: @color-grey-10;
  --button-border-font-color-hover-secondary: @color-grey-20;
  --button-border-font-color-active-secondary: @color-grey-30;
  --button-ghost-border-width-default: 1px;
  --button-ghost-border-focus-color-primary: @color-white;
  --button-ghost-border-focus-color-secondary: @color-white;
  --button-ghost-font-color-secondary: @color-grey-10;
  --button-ghost-font-color-hover-secondary: @color-grey-20;
  --button-ghost-font-color-active-secondary: @color-grey-30;
  --button-ghost-font-color-focus-secondary: @color-grey-10;
  --button-ghost-font-color-primary: @color-primary-yellow;
  --button-ghost-font-color-hover-primary: @color-primary-yellow-hover;
  --button-ghost-font-color-active-primary: @color-primary-yellow-active;
  --button-ghost-padding-xs: 0;
  --button-ghost-padding-s: 4px;
  --button-ghost-padding-m: 4px;
  --button-ghost-padding-l: none;
  --button-ghost-padding-xl: none;
  --button-ghost-line-height-xs: none;
  --button-ghost-line-height-s: .d-text-regular-r3() [line-height];
  --button-ghost-line-height-m: .d-text-regular-r2() [line-height];
  --button-ghost-line-height-l: none;
  --button-ghost-line-height-xl: none;
  --button-ghost-font-size-xs: none;
  --button-ghost-font-size-s: .d-text-regular-r3() [font-size];
  --button-ghost-font-size-m: .d-text-regular-r2() [font-size];
  --button-ghost-font-size-l: none;
  --button-ghost-font-size-xl: none;
  --button-ghost-font-weight-xs: none;
  --button-ghost-font-weight-s: .d-text-regular-r3() [font-weight];
  --button-ghost-font-weight-m: .d-text-regular-r2() [font-weight];
  --button-ghost-font-weight-l: none;
  --button-ghost-font-weight-xl: none;
  --button-ghost-block-size-xs: 16px;
  --button-ghost-block-size-s: 24px;
  --button-ghost-block-size-m: 28px;
  --button-ghost-block-size-l: none;

  /* Slide with label */
  --slide-with-label-title-margin-bottom: 12px;
  --slide-with-label-title-color: @color-white;
  --slide-with-label-title-font-size: .d-text-regular-r3() [font-size];
  --slide-with-label-title-font-weight: .d-text-regular-r3() [font-weight];
  --slide-with-label-title-line-height: .d-text-regular-r3() [line-height];

  /* Slide */
  --slide-border-color: @color-primary-yellow;
  --slide-m-inline-size: 222px;
  --slide-media-scroller-border-color: @color-primary-yellow;

  /* IconButton */
  --icon-button-close-color: @color-grey-10;
  --icon-button-type-close-hover-color: @color-grey-20;
  --icon-button-type-close-active-color: @color-grey-30;

  /* Slides Stack */
  --slide-stack-slide-border-color: @color-grey-40;
  --slide-stack-stack-background-color: @color-grey-50;
  --slide-stack-stack-third-background-color: @color-grey-60;
  --slide-stack-main-slide-border-s: none;
  --slide-stack-main-slide-border-m: none;
  --slide-stack-slide-shadow: none;
  --slide-stack-slide-width-preview: 112px;
  --slide-stack-slide-border-width-s: 0.5px;
  --slide-stack-slide-border-width-m: 1px;
  --slide-stack-slide-border-radius-s: 2px;
  --slide-stack-slide-border-radius-m: 4px;

  /* Input */
  --input-border: 1px solid @color-grey-40;
  --input-border-radius: 8px;
  --input-border-hover-color: @color-grey-10;
  --input-border-active-color: @color-grey-10;
  --input-border-error-color: @color-error-red;
  --input-border-active-width: 2px;
  --input-label-color: @color-grey-10;
  --input-label-font-size: .d-text-regular-r3[font-size];
  --input-label-line-height: .d-text-regular-r3[line-height];
  --input-label-font-weight: .d-text-regular-r3[font-weight];
  --input-background-color: @color-grey-60;
  --input-color: @color-grey-10;
  --input-active-color: @color-white;
  --input-text-transform: uppercase;
  --input-padding: 10px 12px;
  --input-font-size: .d-text-regular-r1() [font-size];
  --input-line-height: .d-text-regular-r1() [line-height];
  --input-font-weight: .d-text-regular-r1() [font-weight];
  --input-container-row-gap: 4px;
  --input-error-message-font-color: @color-error-red;

  /* Modal */
  --modal-background-color: @color-background-dark;

  /* Toast */
  --toast-text-font-size: .d-text-regular-r3() [font-size];
  --toast-text-line-height: .d-text-regular-r3() [line-height];
  --toast-text-font-weight: .d-text-regular-r3() [font-weight];
  --toast-icon-border-width: 1.5px;
  --toast-icon-shadow: 0 4px 6px @color-box-shadow;
  --toast-shadow: 0 4px 4px @color-box-shadow-2;
  --toast-text-color-error: @color-white;
  --toast-background-color-error: @color-transparency-grey-70;
  --toast-border-color-error: @color-error-red;
  --toast-text-color-favorite: @color-white;
  --toast-background-color-favorite: @color-transparency-grey-70;
  --toast-border-color-favorite: @color-primary-yellow;
  --toast-icon-color-favorite: @color-primary-yellow;
  --toast-icon-background-color-favorite: @color-grey-80;
  --toast-icon-border-color-favorite: @color-primary-yellow;
  --toast-icon-color-error: @color-general-red-50-default;
  --toast-icon-background-color-error: @color-transparency-grey-70;
  --toast-icon-border-color-error: @color-general-red-50-default;

  /* Navigation Item */
  --navigation-item-title-font-size: .d-text-regular-r3[font-size];
  --navigation-item-title-line-height: .d-text-regular-r3[line-height];
  --navigation-item-title-font-weight: .d-text-regular-r3[font-weight];
  --navigation-item-title-marker-width: 12px;
  --navigation-item-title-marker-height: 12px;
  --navigation-item-title-marker-color: @color-primary-yellow;
  --navigation-item-background-color-hover: @color-transparency-grey-50;
  --navigation-item-background-color-active: @color-transparency-white-5;
  --navigation-item-border-radius: 4px;
  --navigation-item-border-padding: 4px 6px 6px 8px;
  --navigation-item-link-color: @color-primary-yellow;
  --navigation-item-link-color-active: @color-white;

  /* Navigation Tree */
  --navigation-item-margin-left: 8px;

  /* CategoryNavigation */
  --category-navigation-min-width: 190px;
  // --category-navigation-nav-padding: 20px; не используется

  /* Icon */
  --arrow-right: url("/src/assets/images/educational/svg/arrowRight.svg");
  --arrow-up: url("/src/assets/images/educational/svg/arrowUp.svg");

  /* Sidebar */
  --sidebar-padding: 12px 12px 16px 24px;

  /* Skeleton */
  --skeleton-loading-background: linear-gradient(
      120deg,
      @color-general-grey-10 34%,
      @color-primary-yellow-hover,
      @color-general-grey-10 66%
    )
    right/300% 100%;

  /* Overlay */
  --overlay-background-color: @color-background-dark-blur-secondary;

  /* Dropdown */
  --dropdown-border-radius: 8px;
  --dropdown-border-width: 1px;
  --dropdown-border-color: @color-grey-40;
  --dropdown-shadow: @shadow-0_5_16_-4-box-6, @shadow-0_4_4_0-box-2;
  --dropdown-row-padding: 8px;
  --dropdown-row-font-color: @color-general-white;
  --dropdown-row-font-size: .d-text-regular-r3() [font-size];
  --dropdown-row-line-height: .d-text-regular-r3() [line-height];
  --dropdown-row-font-weight: .d-text-regular-r3() [font-weight];
  --dropdown-row-background-hover-color: @color-transparency-white-5;
  --dropdown-row-icon-size: 16px;
  --dropdown-row-icon-color: @color-grey-10;
  --dropdown-background-color: @color-background-dark-95;
  --dropdown-button-margin-bottom: 6px;
  --dropdown-button-padding: 16px 8px 16px 8px;
  --dropdown-button-font-color: @color-grey-10;
  --dropdown-button-hover-color: @color-grey-20;
  --dropdown-button-active-color: @color-grey-30;
  --dropdown-button-text-transform: uppercase;

  /* Dialog Template */
  --dialog-template-border-top-radius: 20px;
  --dialog-template-border-color: @color-grey-20;
  --dialog-template-footer-padding: 16px 24px 24px;
  --dialog-template-footer-actions-width: 218px;
  --dialog-template-title-font-size: .d-headline-h2() [font-size];
  --dialog-template-title-line-height: .d-headline-h2() [line-height];
  --dialog-template-title-font-weight: .d-headline-h2() [font-weight];
  --dialog-template-title-font-color: @color-general-white;
  --dialog-template-header-padding: 28px 24px 24px 24px;

  /* Upload Presentations Modal */
  --upload-presentations-modal-upload-icon-color: @color-grey-10;
  --upload-presentations-modal-file-size-text-font-size: .d-text-regular-r4()
    [font-size];
  --upload-presentations-modal-file-size-text-line-height: .d-text-regular-r4()
    [line-height];
  --upload-presentations-modal-file-size-text-font-weight: .d-text-regular-r4()
    [font-weight];
  --upload-presentations-modal-file-size-text-font-color: @color-grey-10;
  --upload-presentations-modal-error-text-font-color: @color-error-red;
  --upload-presentations-modal-error-text-font-size: .d-text-regular-r2()
    [font-size];
  --upload-presentations-modal-error-text-line-height: .d-text-regular-r2()
    [line-height];
  --upload-presentations-modal-error-text-font-weight: .d-text-regular-r2()
    [font-weight];
  --upload-presentations-modal-presentation-name-text-font-size: .d-text-regular-r2(

    )
    [font-size];
  --upload-presentations-modal-presentation-name-text-line-height: .d-text-regular-r2(

    )
    [line-height];
  --upload-presentations-modal-presentation-name-text-font-weight: .d-text-regular-r2(

    )
    [font-weight];
  --upload-presentations-modal-instruction-text-width: 52ch;
  --upload-presentations-modal-cancel-dialog-width: 454px;
  --upload-presentations-modal-settings-error-padding: 18px 8px 0px 8px;
  --upload-presentations-modal-settings-error-container-height: 176px;

  /* TextArea */
  --text-area-numbers-color: var(--input-color);
  --text-area-numbers-font-size: .d-text-regular-r4() [font-size];
  --text-area-numbers-line-height: .d-text-regular-r4() [line-height];
  --text-area-numbers-font-weight: .d-text-regular-r4() [font-weight];

  /* Profile */
  --profile-personal-header-font-size: .d-headline-h1() [font-size];
  --profile-personal-header-line-height: .d-headline-h1() [line-height];
  --profile-personal-header-font-weight: .d-headline-h1() [font-weight];

  --scroll-bar-track-color: #232323;
  --scroll-bar-thumb-color: @color-grey-10;

  /* Empty Page Content */
  --empty-page-content-color: @color-grey-10;
  --empty-page-content-font-size: .d-text-regular-r2() [font-size];
  --empty-page-content-line-height: .d-text-regular-r2() [line-height];
  --empty-page-content-font-weight: .d-text-regular-r2() [font-weight];
}

.dark-variables() {
  --color-general-grey-20-grey-90: @color-general-grey-90;
  --color-general-light-blue-primary-grey-70-secondary: @color-general-grey-70-secondary;

  /* Background */
  --background-general-grey-5-background-dark: @color-background-dark;
  --background-light-blur-dark-blur: @color-background-dark-blur;
  --background-general-white-background-dark: @color-background-dark;
  --background-general-white-stack-dark: @color-stack-dark;
  --background-light-blur-90-dark-blur: @color-background-dark-blur;

  /* Font */
  --font-color-general-black-white: @color-general-white;
  --font-color-black-2-white: @color-general-white;
  --font-general-grey-50-light-secondary-grey-60: @color-general-grey-60;
  --general-white-grey-70-secondary: @color-general-white;
  --general-white-grey-50-light-secondary: @color-general-white;

  /* Surface */
  --surface-color-general-grey-1-light-10: @color-light-10;

  /* Icon */
  --icon-color-general-grey-40-grey-5: @color-general-grey-5;
  --icon-color-general-grey-10-grey-50-light-secondary: @color-general-grey-10;
  --icon-color-general-grey-60-grey-20: @color-general-grey-20;

  /* Border */
  --border-color-general-grey-40-grey-90: @color-general-grey-90;
  --border-color-general-grey-50-light-secondary-grey-90: @color-general-grey-90;
  --color-transparency-dark-20-transparency-light-20: @color-transparency-light-20;
  --border-general-black-light-blue-40-alternative-primary: @color-general-light-blue-40-alternative-primary;
  --general-white-light-blue-70: @color-general-white;
  --general-white-grey-80: @color-general-grey-80;
  --general-white-grey-90: @color-general-grey-90;

  /* Dropdown */
  --row-font-weight-500-700: 700;

  --color-white-transparency-grey-40: @color-transparency-grey-40;
  --color-general-gray-5-transparency-gray-40: @color-transparency-grey-40;
  --color-general-gray-10-bg-dark: @color-bg-dark;
  --color-general-white-empty: none;
  --color-grey-40-blue-20: @color-general-blue-20;
  --color-general-white-grey-100: @color-general-grey-100;
  --color-light-blue-primary-grey-100: @color-general-grey-100;
  --color-light-blue-60-grey-90: @color-general-grey-90;
  --color-light-blue-70-blue-20: @color-general-blue-20;
  --color-white-light-blue-primary: @color-general-light-blue-primary;

  --color-grey-10-transparency-light-5: @color-transparency-light-5;
  --color-general-grey-10-grey-100: @color-general-grey-100;
  --color-general-grey-30-grey-90: @color-general-grey-90;

  --color-general-grey-10-transparency-light-10: @color-transparency-light-10;
  --color-general-grey-80-general-white: @color-general-white;
  --color-general-grey-20-transparency-light-20: @color-transparency-light-10;
  --color-grey-70-secondary-grey-80: @color-general-grey-80;

  --color-grey-40-grey-70-secondary: @color-general-grey-70-secondary;
  --color-transparency-dark-20-grey-20: @color-transparency-grey-20;
  --color-opacity-10-transparency-light-5: @color-transparency-light-5;

  --color-linear-gradient-grey-dark: @color-linear-gradient-dark;
  --color-linear-gradient-grey-dark-transparent: @color-linear-gradient-dark-transparent;

  --shadow-uploaded-tooltip: @shadow-0_5_16_-4-box-6,
    @shadow-0_4_4_0-transparency-dark-20;
  --color-light-blur-grey-20: @color-transparency-grey-20;

  --color-general-grey-60-white: @color-general-white;
  --color-general-black-grey-80: @color-general-grey-80;

  --color-general-grey-1-transparency-light-10: @color-transparency-light-10;
  --color-general-white-black: @color-general-black;
  --color-general-grey-20-grey-80: @color-general-grey-80;
  --color-general-grey-1-background-dark: @color-background-dark;
}

.light-variables() {
  --color-general-grey-20-grey-90: @color-general-grey-20;
  --color-general-light-blue-primary-grey-70-secondary: @color-general-light-blue-primary;

  /* Background */
  --background-general-grey-5-background-dark: @color-general-grey-5;
  --background-light-blur-dark-blur: @color-background-light-blur-90;
  --background-general-white-background-dark: @color-general-white;
  --background-general-white-stack-dark: @color-general-white;
  --background-light-blur-90-dark-blur: @color-background-light-blur-90;

  /* Font */
  --font-color-general-black-white: @color-general-black;
  --font-color-black-2-white: @color-black-2;
  --font-general-grey-50-light-secondary-grey-60: @color-general-grey-50-light-secondary;
  --general-white-grey-70-secondary: @color-general-grey-70-secondary;
  --general-white-grey-50-light-secondary: @color-general-grey-50-light-secondary;

  /* Surface */
  --surface-color-general-grey-1-light-10: @color-general-grey-1;

  /* Icon */
  --icon-color-general-grey-40-grey-5: @color-general-grey-40;
  --icon-color-general-grey-10-grey-50-light-secondary: @color-general-grey-50-light-secondary;
  --icon-color-general-grey-60-grey-20: @color-general-grey-60;

  /* Border */
  --border-color-general-grey-40-grey-90: @color-general-grey-40;
  --border-color-general-grey-50-light-secondary-grey-90: @color-general-grey-50-light-secondary;
  --color-transparency-dark-20-transparency-light-20: @color-transparency-dark-20;
  --border-general-black-light-blue-40-alternative-primary: @color-general-black;
  --general-white-light-blue-70: @color-general-light-blue-70;
  --general-white-grey-80: @color-general-white;
  --general-white-grey-90: @color-general-white;

  /* Dropdown */
  --row-font-weight-500-700: 500;

  --color-white-transparency-grey-40: @color-general-white;
  --color-general-gray-5-transparency-gray-40: @color-general-grey-5;
  --color-general-gray-10-bg-dark: @color-general-grey-10;
  --color-general-white-empty: @color-general-white;
  --color-grey-40-blue-20: @color-general-grey-40;
  --color-general-white-grey-100: @color-general-white;
  --color-light-blue-primary-grey-100: @color-general-light-blue-primary;
  --color-light-blue-60-grey-90: @color-general-light-blue-60;
  --color-light-blue-70-blue-20: @color-general-light-blue-70;
  --color-white-light-blue-primary: @color-general-white;
  --color-grey-10-transparency-light-5: @color-general-grey-10;
  --color-general-grey-10-grey-100: @color-general-grey-10;
  --color-general-grey-30-grey-90: @color-general-grey-30;

  --color-general-grey-10-transparency-light-10: @color-general-grey-10;
  --color-general-grey-80-general-white: @color-general-grey-80;
  --color-general-grey-20-transparency-light-20: @color-general-grey-20;
  --color-grey-70-secondary-grey-80: @color-general-grey-70-secondary;
  --color-grey-40-grey-70-secondary: @color-general-grey-40;
  --color-transparency-dark-20-grey-20: @color-transparency-dark-20;
  --color-opacity-10-transparency-light-5: @color-opacity-10;

  --color-linear-gradient-grey-dark: @color-linear-gradient-grey;
  --color-linear-gradient-grey-dark-transparent: @color-linear-gradient-grey-transparent;

  --shadow-uploaded-tooltip: @shadow-0_8_24_-4-box-8, @shadow-0_5_16_-4-box-6;
  --color-light-blur-grey-20: @color-background-light-blur;

  --color-general-grey-60-white: @color-general-grey-60;
  --color-general-black-grey-80: @color-general-black;

  --color-general-grey-1-transparency-light-10: @color-general-grey-1;
  --color-general-white-black: @color-general-white;
  --color-general-grey-20-grey-80: @color-general-grey-20;
  --color-general-grey-1-background-dark: @color-general-grey-1;
}

.light-corporate() {
  color-scheme: light;
  .light-variables();
}

.dark-corporate() {
  color-scheme: dark;
  .dark-variables();
}

:root {
  &.corporate {
    @media (prefers-color-scheme: light) {
      .light-corporate();
    }

    @media (prefers-color-scheme: dark) {
      .dark-corporate();
    }

    &[color-scheme="dark"] {
      .dark-corporate();
    }

    &[color-scheme="light"] {
      .light-corporate();
    }
  }
}

html.corporate {
  color-scheme: light dark;
  text-transform: none;

  --text-transform: none;

  textarea {
    &::placeholder,
    &::-webkit-input-placeholder {
      opacity: 1;
      color: @color-general-grey-50-light-secondary;
    }
  }

  /* Typography */
  --h0-size: 96px;
  --h0-height: 88px;
  --h1-size: 80px;
  --h1-height: 72px;
  --h2-size: 64px;
  --h2-height: 64px;
  --h3-size: 48px;
  --h3-height: 48px;
  --h4-size: 36px;
  --h4-height: 40px;
  --h5-size: 28px;
  --h5-height: 32px;
  --h6-size: 20px;
  --h6-height: 24px;
  --h-weight: 500;

  /* Text */
  --mobile-t-m1-size: 16px;
  --mobile-t-m1-height: 24px;
  --mobile-t-m2-size: 14px;
  --mobile-t-m2-height: 20px;
  --mobile-t-m3-size: 12px;
  --mobile-t-m3-height: 16px;
  --mobile-t-m4-size: 12px;
  --mobile-t-m4-height: 12px;
  --mobile-t-m-weight: 500;
  --mobile-t-r3-size: 12px;
  --mobile-t-r3-height: 16px;
  --mobile-t-r3-weight: 400;

  --t-m1-size: 20px;
  --t-m1-height: 28px;
  --t-m2-size: 16px;
  --t-m2-height: 22.4px;
  --t-m3-size: 14px;
  --t-m3-height: 19.6px;
  --t-m4-size: 12px;
  --t-m4-height: 16.8px;
  --t-m-weight: 500;
  --t-r-weight: 400;
  --t-r1-size: 20px;
  --t-r1-height: 28px;
  --t-r2-size: 16px;
  --t-r2-height: 24px;
  --t-r3-size: 14px;
  --t-r3-height: 20px;
  --t-r4-size: 12px;
  --t-r4-height: 16px;

  /* Main */
  --background-color: var(--background-general-white-background-dark);

  /* Checkbox */

  --checkbox-style-slide-border-color: @color-general-white;
  --checkbox-style-presentation-border-color: var(
    --color-general-grey-20-grey-90
  );
  --checkbox-style-presentation-border-hover-color: var(
    --color-grey-40-blue-20
  );
  --checkbox-check-border-checked-color: var(--general-white-grey-80);
  --checkbox-style-presentation-checked-background-color: var(
    --color-light-blue-primary-grey-100
  );
  --checkbox-check-border-checked-color-light: @color-general-white;
  --checkbox-flag-border-checked-color: @color-general-white;
  --checkbox-style-slide-background-color: @color-light-20;
  --checkbox-style-presentation-background-color: var(
    --color-general-white-grey-100
  );
  --checkbox-style-presentation-checked-border-color: var(
    --color-light-blue-60-grey-90
  );
  --checkbox-style-presentation-checked-border-hover-color: var(
    --color-light-blue-70-blue-20
  );

  --checkbox-check-background-checked-color: @color-general-black;
  --checkbox-flag-background-checked-color: @color-general-green-50-default;
  --checkbox-mask-checked-color: @color-general-white;
  --checkbox-style-presentation-mask-checked: var(
    --color-white-light-blue-primary
  );
  --checkbox-flag-mask-color: @color-general-white;

  /* Button */
  --button-fill-background-default-color-primary: @color-general-light-blue-primary;
  --button-fill-background-hover-color-primary: @color-general-blue-60;
  --button-fill-background-active-color-primary: @color-general-blue-70;
  --button-fill-background-disable-color-primary: none;
  --button-fill-background-default-color-secondary: @color-general-grey-5;
  --button-fill-background-hover-color-secondary: @color-general-grey-10;
  --button-fill-background-active-color-secondary: @color-general-grey-20;
  --button-fill-border-focus-color-primary: @color-general-light-blue-70;
  --button-fill-border-disable-color-primary: @color-general-grey-10;
  --button-fill-border-focus-color-secondary: @color-general-light-blue-70;
  --button-fill-border-focus-width: 3px;
  --button-fill-border-disabled-width: 1px;
  --button-fill-padding-s: 6px 12px;
  --button-fill-padding-m: 12px 24px;
  --button-fill-padding-l: 16px 12px;
  --button-fill-padding-xl: none;
  --button-fill-block-size-s: 36px;
  --button-fill-block-size-m: 48px;
  --button-fill-block-size-l: 60px;
  --button-fill-block-size-xl: none;
  --button-fill-line-height-s: .d-text-medium-m2[line-height];
  --button-fill-line-height-m: .d-text-medium-m2[line-height];
  --button-fill-line-height-l: .d-text-medium-m1[line-height];
  --button-fill-line-height-xl: none;
  --button-fill-font-size-s: .d-text-medium-m2[font-size];
  --button-fill-font-size-m: .d-text-medium-m2[font-size];
  --button-fill-font-size-l: .d-text-medium-m1[font-size];
  --button-fill-font-size-xl: none;
  --button-fill-font-weight-s: .d-text-medium-m2[font-weight];
  --button-fill-font-weight-m: .d-text-medium-m2[font-weight];
  --button-fill-font-weight-l: .d-text-medium-m1[font-weight];
  --button-fill-font-weight-xl: none;
  --button-fill-font-color-primary: @color-general-white;
  --button-fill-font-color-hover-primary: @color-general-white;
  --button-fill-font-color-active-primary: @color-general-white;
  --button-fill-font-color-disable-primary: @color-general-grey-30;
  --button-fill-font-color-secondary: @color-general-light-blue-primary;
  --button-fill-font-color-hover-secondary: @color-general-light-blue-primary;
  --button-fill-font-color-active-secondary: @color-general-light-blue-primary;
  --button-border-focus-color: @color-general-light-blue-70;
  --button-border-border-default-color: none;
  --button-border-border-hover-color-secondary: none;
  --button-border-border-focus-color-secondary: @color-general-light-blue-70;
  --button-border-border-focus-width-secondary: 3px;
  --button-border-background-default-color: none;
  --button-border-background-default-color-secondary: @color-general-grey-5;
  --button-border-background-hover-color-secondary: @color-general-grey-10;
  --button-border-background-active-color-secondary: @color-general-grey-20;
  --button-border-padding-xs: none;
  --button-border-padding-s: none;
  --button-border-padding-m: 12px 24px;
  --button-border-padding-l: 16px 12px;
  --button-border-padding-xl: none;
  --button-border-block-size-m: 48px;
  --button-border-block-size-l: 60px;
  --button-border-font-size-xs: none;
  --button-border-font-size-s: .d-text-medium-m2[font-size];
  --button-border-font-size-m: .d-text-medium-m2[font-size];
  --button-border-font-size-l: .d-text-medium-m1[font-size];
  --button-border-font-size-xl: none;
  --button-border-font-weight-s: .d-text-medium-m2[font-weight];
  --button-border-font-weight-m: .d-text-medium-m2[font-weight];
  --button-border-font-weight-l: .d-text-medium-m1[font-weight];
  --button-border-line-height-s: .d-text-medium-m2[line-height];
  --button-border-line-height-m: .d-text-medium-m2[line-height];
  --button-border-line-height-l: .d-text-medium-m1[line-height];
  --button-border-font-color-secondary: @color-general-light-blue-primary;
  --button-border-font-color-hover-secondary: @color-general-light-blue-primary;
  --button-border-font-color-active-secondary: @color-general-light-blue-primary;
  --button-ghost-border-width-default: 2px;
  --button-ghost-border-focus-color-primary: @color-general-light-blue-70;
  --button-ghost-border-focus-color-secondary: var(
    --general-white-light-blue-70
  );
  --button-ghost-font-color-secondary: @color-general-grey-50-light-secondary;
  --button-ghost-font-color-hover-secondary: var(
    --general-white-grey-70-secondary
  );
  --button-ghost-font-color-active-secondary: var(
    --general-white-grey-70-secondary
  );
  --button-ghost-font-color-focus-secondary: var(
    --general-white-grey-50-light-secondary
  );
  --button-ghost-font-color-primary: @color-general-light-blue-primary;
  --button-ghost-font-color-hover-primary: @color-general-blue-60-hover;
  --button-ghost-font-color-active-primary: @color-general-blue-60-hover;
  // --button-ghost-padding-xs: 4px 12px;
  --button-ghost-padding-xs: 4px 12px;
  --button-ghost-padding-s: 4px 12px;
  --button-ghost-padding-m: 4px 12px;
  --button-ghost-padding-l: none;
  --button-ghost-padding-xl: none;
  --button-ghost-line-height-xs: .d-text-regular-r4() [line-height];
  --button-ghost-line-height-s: .d-text-medium-m4() [line-height];
  --button-ghost-line-height-m: .m-text-medium-m1() [line-height];
  --button-ghost-line-height-l: none;
  --button-ghost-line-height-xl: none;
  --button-ghost-font-size-xs: .d-text-regular-r4() [font-size];
  --button-ghost-font-size-s: .d-text-medium-m4() [font-size];
  --button-ghost-font-size-m: .m-text-medium-m1() [font-size];
  --button-ghost-font-size-l: none;
  --button-ghost-font-size-xl: none;
  --button-ghost-font-weight-xs: .d-text-regular-r4() [font-weight];
  --button-ghost-font-weight-s: .d-text-medium-m4() [font-weight];
  --button-ghost-font-weight-m: .m-text-medium-m1() [font-weight];
  --button-ghost-font-weight-l: none;
  --button-ghost-font-weight-xl: none;
  --button-ghost-block-size-xs: 16px;
  --button-ghost-block-size-s: 24px;
  --button-ghost-block-size-m: 28px;
  --button-ghost-block-size-l: none;

  /* Slide with label */
  --slide-with-label-title-margin-bottom: 8px;
  --slide-with-label-title-color: var(--font-color-general-black-white);
  --slide-with-label-title-font-size: .d-text-regular-r4() [font-size];
  --slide-with-label-title-font-weight: .d-text-regular-r4() [font-weight];
  --slide-with-label-title-line-height: .d-text-regular-r4() [line-height];

  /* Slide */
  --slide-border-color: var(
    --border-general-black-light-blue-40-alternative-primary
  );
  --slide-m-inline-size: 222px;
  --slide-media-scroller-border-color: @color-general-light-blue-40-alternative-primary;

  /* IconButton */
  --icon-button-close-color: var(--icon-color-general-grey-40-grey-5);
  --icon-button-type-close-hover-color: var(
    --icon-color-general-grey-10-grey-50-light-secondary
  );
  --icon-button-type-close-active-color: var(
    --icon-color-general-grey-60-grey-20
  );

  /* Slides Stack */
  --slide-stack-slide-border-color: var(
    --color-transparency-dark-20-transparency-light-20
  );
  --slide-stack-stack-background-color: var(
    --background-general-white-stack-dark
  );
  --slide-stack-stack-third-background-color: var(
    --background-general-white-stack-dark
  );
  --slide-stack-main-slide-border-s: var(--slide-stack-slide-border-width-s)
    solid var(--slide-stack-slide-border-color);
  --slide-stack-main-slide-border-m: var(--slide-stack-slide-border-width-m)
    solid var(--slide-stack-slide-border-color);
  --slide-stack-slide-shadow: 0px 1px 2px hsl(0deg 0% 19% / 0.15);
  --slide-stack-slide-width-preview: 112px;
  --slide-stack-slide-border-width-s: 0.5px;
  --slide-stack-slide-border-width-m: 1px;
  --slide-stack-slide-border-radius-s: 3px;
  --slide-stack-slide-border-radius-m: 4px;

  /* Input */
  --input-border: 1px solid var(--color-general-grey-20-grey-90);
  --input-border-radius: 8px;
  --input-border-hover-color: var(--border-color-general-grey-40-grey-90);
  --input-border-active-color: var(
    --color-general-light-blue-primary-grey-70-secondary
  );
  --input-border-error-color: @color-general-red-50-default;
  --input-border-active-width: 1px;
  --input-label-color: @color-general-grey-50-light-secondary;
  --input-label-font-size: .d-text-regular-r4[font-size];
  --input-label-line-height: .d-text-regular-r4[line-height];
  --input-label-font-weight: .d-text-regular-r4[font-weight];
  --input-background-color: transparent;
  --input-color: var(--font-general-grey-50-light-secondary-grey-60);
  --input-active-color: var(--font-color-general-black-white);
  --input-text-transform: none;
  --input-padding: 8px 8px 8px 12px;
  --input-font-size: .d-text-regular-r1[font-size];
  --input-line-height: .d-text-regular-r1[line-height];
  --input-font-weight: .d-text-regular-r1[font-weight];
  --input-container-row-gap: 4px;
  --input-error-message-font-color: @color-general-red-50-default;

  /* Modal */
  --modal-background-color: var(--background-general-white-background-dark);
  --modal-box-shadow: 0 9.74px 29.21px -4.87px @color-general-box-shadow-8,
    0 6.08px 19.47px -4.87px @color-general-box-shadow-6;

  /* Toast */
  --toast-text-font-size: 16px;
  --toast-text-line-height: 22.4px;
  --toast-text-font-weight: 400;
  --toast-icon-border-width: 1.2px;
  --toast-icon-shadow: 0 4px 6px @color-box-shadow;
  --toast-shadow: 0 8px 24px -4px @color-general-box-shadow-8,
    0 5px 16px -4px @color-general-box-shadow-6;
  --toast-text-color-error: @color-general-black;
  --toast-background-color-error: @color-general-red-1;
  --toast-border-color-error: @color-general-red-50-default;
  --toast-text-color-favorite: @color-general-black;
  --toast-background-color-favorite: @color-general-grey-5;
  --toast-border-color-favorite: @color-general-green-70-hover;
  --toast-icon-background-color-favorite: @color-general-green-50-default;
  --toast-icon-color-favorite: @color-general-white;
  --toast-icon-border-color-favorite: @color-general-white;
  --toast-icon-color-error: @color-general-red-50-default;
  --toast-icon-border-color-error: @color-general-red-50-default;

  /* Navigation Item */
  --navigation-item-title-font-size: .d-text-regular-r3[font-size];
  --navigation-item-title-line-height: .d-text-regular-r3[line-height];
  --navigation-item-title-font-weight: .d-text-regular-r3[font-weight];
  --navigation-item-title-marker-width: 16px;
  --navigation-item-title-marker-height: 16px;
  --navigation-item-title-marker-color: @color-general-grey-60;
  --navigation-item-background-color-hover: var(
    --color-general-grey-20-grey-90
  );
  --navigation-item-background-color-active: var(
    --color-white-transparency-grey-40
  );
  --navigation-item-border-radius: 4px;
  --navigation-item-border-padding: 4px 4px 4px 8px;
  --navigation-item-link-color: var(--font-color-general-black-white);
  --navigation-item-link-color-active: @color-general-light-blue-primary;

  /* Navigation Tree */
  --navigation-item-margin-left: 8px;

  /* CategoryNavigation */
  --category-navigation-min-width: 226px;
  --category-navigation-nav-padding: 12px 12px 16px 24px;

  /* Icon */
  --arrow-right: url("/src/assets/images/corporate/svg/arrowRight.svg");
  --arrow-up: url("/src/assets/images/corporate/svg/arrowUp.svg");

  /* Sidebar */
  --sidebar-padding: 12px 12px 16px 24px;

  /* Skeleton */
  --skeleton-loading-background: linear-gradient(
      120deg,
      @color-skeleton-background 34%,
      @color-skeleton-transition,
      @color-skeleton-background 66%
    )
    right/300% 100%;

  /* Overlay */
  --overlay-background-color: var(
    --color-transparency-dark-20-transparency-light-20
  );

  /* Dropdown */
  --dropdown-border-radius: 8px;
  --dropdown-border-width: 1px;
  --dropdown-border-color: var(--color-general-grey-20-grey-90);
  --dropdown-shadow: @shadow-0_5_16_-4-box-6, @shadow-0_4_4_0-box-2;
  --dropdown-row-padding: 8px;
  --dropdown-row-font-color: var(--font-color-general-black-white);
  --dropdown-row-font-size: 16px;
  --dropdown-row-line-height: 24px;
  --dropdown-row-font-weight: var(--row-font-weight-500-700);
  --dropdown-row-background-hover-color: var(
    --color-general-gray-5-transparency-gray-40
  );
  --dropdown-row-icon-size: 16px;
  --dropdown-row-icon-color: @color-general-grey-50-light-secondary;
  --dropdown-background-color: var(--background-general-white-background-dark);
  --dropdown-button-margin-bottom: 6px;
  --dropdown-button-padding: 16px 8px 16px 8px;
  --dropdown-button-font-color: @color-general-grey-50-light-secondary;
  --dropdown-button-hover-color: @color-general-grey-50-light-secondary;
  --dropdown-button-active-color: @color-general-grey-50-light-secondary;
  --dropdown-button-text-transform: none;
  --dropdown-button-background-hover-color: var(
    --dropdown-row-background-hover-color
  );

  /* Dialog Template */
  --dialog-template-border-top-radius: 20px;
  --dialog-template-border-color: var(--color-general-grey-20-grey-90);
  --dialog-template-footer-padding: 16px 24px 24px;
  --dialog-template-footer-actions-width: 200px;
  --dialog-template-title-font-size: .d-headline-h4() [font-size];
  --dialog-template-title-line-height: .d-headline-h4() [line-height];
  --dialog-template-title-font-weight: .d-headline-h4() [font-weight];
  --dialog-template-title-font-color: var(--font-color-general-black-white);
  --dialog-template-header-padding: 24px;

  /* Upload Presentations Modal */
  --upload-presentations-modal-upload-icon-color: @color-general-grey-50-light-secondary;
  --upload-presentations-modal-file-size-text-font-size: .d-text-medium-m1()
    [font-size];
  --upload-presentations-modal-file-size-text-line-height: .d-text-medium-m1()
    [line-height];
  --upload-presentations-modal-file-size-text-font-weight: .d-text-medium-m1()
    [font-weight];
  --upload-presentations-modal-file-size-text-font-color: @color-general-grey-50-light-secondary;
  --upload-presentations-modal-error-text-font-color: @color-general-red-50-default;
  --upload-presentations-modal-error-text-font-size: .d-text-medium-m1()
    [font-size];
  --upload-presentations-modal-error-text-line-height: .d-text-medium-m1()
    [line-height];
  --upload-presentations-modal-error-text-font-weight: .d-text-medium-m1()
    [font-weight];
  --upload-presentations-modal-presentation-name-text-font-size: .d-text-medium-m2(

    )
    [font-size];
  --upload-presentations-modal-presentation-name-text-line-height: .d-text-medium-m2(

    )
    [line-height];
  --upload-presentations-modal-presentation-name-text-font-weight: .d-text-medium-m2(

    )
    [font-weight];
  --upload-presentations-modal-presentation-name-text-font-color: var(
    --font-color-general-black-white
  );
  --upload-presentations-modal-instruction-text-font-size: .d-headline-h5()
    [font-size];
  --upload-presentations-modal-instruction-text-line-height: .d-headline-h5()
    [line-height];
  --upload-presentations-modal-instruction-text-font-weight: .d-headline-h5()
    [font-weight];
  --upload-presentations-modal-instruction-text-font-color: var(
    --font-color-general-black-white
  );
  --upload-presentations-modal-instruction-text-width: 40ch;
  --upload-presentations-modal-cancel-dialog-width: 570px;
  --upload-presentations-modal-settings-error-padding: 18px 8px 0px 8px;
  --upload-presentations-modal-settings-error-container-height: 192px;

  /* TextArea */
  --text-area-numbers-color: var(--input-color);
  --text-area-numbers-font-size: .d-text-medium-m4() [font-size];
  --text-area-numbers-line-height: .d-text-medium-m4() [line-height];
  --text-area-numbers-font-weight: .d-text-medium-m4() [font-weight];

  /* Profile */
  --profile-personal-background-color: var(--color-general-gray-10-bg-dark);
  --profile-personal-header-font-size: .d-headline-h4() [font-size];
  --profile-personal-header-line-height: .d-headline-h4() [line-height];
  --profile-personal-header-font-weight: .d-headline-h4() [font-weight];

  --profile-personal-input-background-color: var(--color-general-white-empty);

  --scroll-bar-track-color: var(--color-general-grey-10-grey-100);
  --scroll-bar-thumb-color: var(--color-general-grey-30-grey-90);

  /* Empty Page Content */
  --empty-page-content-color: @lightGray;
  --empty-page-content-font-size: .d-text-regular-r2() [font-size];
  --empty-page-content-line-height: .d-text-regular-r2() [line-height];
  --empty-page-content-font-weight: .d-text-regular-r2() [font-weight];
}

.d-headline-h1() {
  font-size: var(--h1-size);
  line-height: var(--h1-height);
  font-weight: var(--h-weight);
}
.d-headline-h2() {
  font-size: var(--h2-size);
  font-weight: var(--h-weight);
  line-height: var(--h2-height);
}
.d-headline-h3() {
  font-size: var(--h3-size);
  font-weight: var(--h-weight);
  line-height: var(--h3-height);
}
.d-headline-h4() {
  font-size: var(--h4-size);
  font-weight: var(--h-weight);
  line-height: var(--h4-height);
}
.d-headline-h5() {
  font-size: var(--h5-size);
  font-weight: var(--h-weight);
  line-height: var(--h5-height);
}
.d-headline-h6() {
  font-size: var(--h6-size);
  font-weight: var(--h-weight);
  line-height: var(--h6-height);
}
.d-text-regular-r1() {
  font-size: var(--t-r1-size);
  line-height: var(--t-r1-height);
  font-weight: var(--t-r-weight);
}
.d-text-regular-r2() {
  font-size: var(--t-r2-size);
  line-height: var(--t-r2-height);
  font-weight: var(--t-r-weight);
}
.d-text-regular-r3() {
  font-size: var(--t-r3-size);
  line-height: var(--t-r3-height);
  font-weight: var(--t-r-weight);
}
.d-text-regular-r4() {
  font-size: var(--t-r4-size);
  line-height: var(--t-r4-height);
  font-weight: var(--t-r-weight);
}
.d-text-medium-m1() {
  font-size: var(--t-m1-size);
  font-weight: var(--t-m-weight);
  line-height: var(--t-m1-height);
}
.d-text-medium-m2() {
  //TODO поправить на переменные. А какие - это надо смотреть по дизайну, который меняется 100500 раз в секунду
  font-size: 16px;
  font-weight: var(--t-m-weight);
  line-height: 20px;
}
.d-text-medium-m3() {
  font-size: 14px;
  font-weight: var(--t-m-weight);
  line-height: 18px;
}
.d-text-medium-m4() {
  font-size: var(--t-m4-size);
  font-weight: var(--t-m-weight);
  line-height: var(--t-m4-height);
}

.m-text-medium-m1() {
  font-size: var(--mobile-t-m1-size);
  font-weight: var(--mobile-t-m-weight);
  line-height: var(--mobile-t-m1-height);
}
.m-text-medium-m2() {
  font-size: var(--mobile-t-m2-size);
  font-weight: var(--mobile-t-m-weight);
  line-height: var(--mobile-t-m2-height);
}
.m-text-medium-m3() {
  font-size: var(--mobile-t-m3-size);
  font-weight: var(--mobile-t-m-weight);
  line-height: var(--mobile-t-m3-height);
}
.m-text-medium-m4() {
  font-size: var(--mobile-t-m4-size);
  font-weight: var(--mobile-t-m-weight);
  line-height: var(--mobile-t-m4-height);
}

.d-thin-text {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.arrow-right-icon() {
  mask-image: var(--arrow-right);
  -webkit-mask-image: var(--arrow-right);
  @centeredMask();
}

.arrow-up-icon() {
  mask-image: var(--arrow-up);
  -webkit-mask-image: var(--arrow-up);
  @centeredMask();
}

.skeleton-animation() {
  // TODO: Доделать, так как сейчас не работает
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  animation: to-left 1.5s linear infinite;

  @keyframes to-left {
    from {
      background-position-x: 0%;
    }

    to {
      background-position-x: -200%;
    }
  }
}

.custom-scroll() {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    .borderRadius(2px);
    background: var(--scroll-bar-track-color);
  }

  &::-webkit-scrollbar-thumb {
    .borderRadius(2px);
    background: var(--scroll-bar-thumb-color);
  }
}

.shine() {
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
}
