@import (reference) "/src/assets/less/styles.less";

html[class="educational"] {
  --service-skeleton-button-background-color: @color-grey-20;

  --service-message-header-font-color: var(--font-color-general-black-white);
  --service-message-header-font-size: 28px;
  --service-message-header-line-height: 32px;
  --service-message-header-font-weight: 900;
  --service-message-header-margin-bottom: 24px;

  --service-message-text-font-color: @color-general-grey-50-light-secondary;
  --service-message-text-font-size: 16px;
  --service-message-text-line-height: 20px;
  --service-message-text-font-weight: 900;

  --service-message-code-font-color: @color-primary-yellow;
  --service-message-code-font-weight: 900;
  --service-message-code-letter-spacing: calc(-3px);
}

html[class="corporate"] {
  --service-skeleton-button-background-color: @color-general-grey-20;

  --service-message-header-font-color: var(--font-color-general-black-white);
  --service-message-header-font-size: .d-headline-h5() [font-size];
  --service-message-header-line-height: .d-headline-h5() [line-height];
  --service-message-header-font-weight: .d-headline-h5() [font-weight];
  --service-message-header-margin-bottom: 12px;

  --service-message-text-font-color: @color-general-grey-50-light-secondary;
  --service-message-text-font-size: .d-headline-h6() [font-size];
  --service-message-text-line-height: .d-headline-h6() [line-height];
  --service-message-text-font-weight: .d-headline-h6() [font-weight];

  --service-message-code-font-color: @color-general-light-blue-primary;
  --service-message-code-font-weight: 500;
  --service-message-code-letter-spacing: calc(-4px);
}

.container {
  display: grid;
  gap: 24px;
  justify-items: center;
  align-items: center;

  .header {
    text-align: center;
    color: var(--service-message-header-font-color);
    font-size: var(--service-message-header-font-size);
    line-height: var(--service-message-header-line-height);
    font-weight: var(--service-message-header-font-weight);
    margin-bottom: var(--service-message-header-margin-bottom);
  }

  .text {
    text-align: center;
    color: var(--service-message-text-font-color);
    font-size: var(--service-message-text-font-size);
    line-height: var(--service-message-text-line-height);
    font-weight: var(--service-message-text-font-weight);
  }

  .code {
    color: var(--service-message-code-font-color);
    font-size: 120px;
    line-height: 120px;
    font-weight: var(--service-message-code-font-weight);
    letter-spacing: var(--service-message-code-letter-spacing);
  }
}

@media (width <= 1440px) {
  .container {
    max-width: 508px;
  }
}

.skeletonButton {
  width: 211px;
  height: 48px;
  border-radius: 24px;
  background-color: var(--service-skeleton-button-background-color);
}
